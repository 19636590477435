/*
 * File: utilities.js
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: April 10, 2024 at 11:50 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Constants
const { PASSWORD_REGEX, SCROLL_LOAD_TOP } = require('../../../constants/general.constants');


/**
 * Alert Handlers
 */

const showAlert = (message, idObj, bottom, textClass) => {
	let id = idObj;

	// Get id
	if (id == null) id = 'input-alert';

	// Get elements
	const alert = $(`#${id}`);
	const alertText = $(`#${id}-text`);

	// Get alert visibility
	const isAlertVisible = alert.css('top') !== '0px';
	if (!isAlertVisible) {

		// Set alert content
		alertText.text(message);
		alertText.attr('class', 'input-alert-inner');
		alertText.addClass('shadow');
		if (textClass != null) alertText.addClass(textClass);

		// Hide element
		setTimeout(() => { alert.addClass('solid-element'); }, 100);

		// Show alert
		if (bottom === true) {
			alert.animate({ top: '20px' }, 300, null);
		} else {
			alert.animate({ top: `-${alert.outerHeight() + 15}px` }, 300, null);
		}

		// Scroll to top to see error
		if ($('html, body').scrollTop() > 130) {
			$('html, body').animate({ scrollTop: '130px' }, 300);
		}
	}
};

const hideAlert = (idObj) => {
	let id = idObj;

	// Get id
	if (id == null) id = 'input-alert';

	// Get elements
	const alert = $(`#${id}`);
	const alertText = $(`#${id}-text`);

	// Get alert visibility
	const isAlertVisible = alert.css('top') !== '0px';
	if (isAlertVisible) {

		// Set alert content
		alertText.removeClass('shadow');

		// Hide element
		setTimeout(() => { alert.removeClass('solid-element'); }, 200);

		// Hide alert
		alert.animate({ top: '0px' }, 300, () => {

			// Remove content
			alertText.text('');
		});
	}
};


/**
 * Modal Handlers
 */

const showModalWithId = (id) => {
	if (!$(`#${id}`).hasClass('show-modal')) {
		$(`#${id}`).addClass('show-modal');
	}
};

const showModalWithComponent = (component, data, container) => {
	const modal = component(data);
	$(`#${container}`).append(modal);
	setTimeout(() => {
		const numberChildren = $(`#${container}`).children().length;
		$($(`#${container}`).children()[numberChildren - 1]).addClass('show-modal');
	}, 250);
};

const hideModalWithId = (id) => {
	if ($(`#${id}`).hasClass('show-modal')) {
		$(`#${id}`).removeClass('show-modal');
	}
};

const hideModal = (container, id) => {
	if (id != null) {
		$(`#${container} #${id}`).removeClass('show-modal');
		setTimeout(() => {
			$(`#${container} #${id}`).remove();
		}, 250);
	} else {
		$(`#${container}`).children().removeClass('show-modal');
		setTimeout(() => {
			$(`#${container}`).html('');
		}, 250);
	}
};


/**
 * General Utilities
 */

const getCookie = (name) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
	return undefined;
};

const getAllUrlParams = (url) => {

	// Get query string from url (optional) or window
	let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

	// We'll store the parameters here
	const obj = {};

	// If query string exists
	if (queryString) {

		// Stuff after # is not part of query string, so get rid of it
		[queryString] = queryString.split('#');

		// Split our query string into its component parts
		const arr = queryString.split('&');
		for (let i = 0; i < arr.length; i += 1) {

			// Separate the keys and the values
			const a = arr[i].split('=');

			// Set parameter name and value (use 'true' if empty)
			const paramName = a[0];
			const paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

			// If the paramName ends with square brackets, e.g. colors[] or colors[2]
			if (paramName.match(/\[(\d+)?\]$/)) {

				// Create key if it doesn't exist
				const key = paramName.replace(/\[(\d+)?\]/, '');
				if (!obj[key]) obj[key] = [];

				// If it's an indexed array e.g. colors[2]
				if (paramName.match(/\[\d+\]$/)) {

					// Get the index value and add the entry at the appropriate position
					const index = /\[(\d+)\]/.exec(paramName)[1];
					obj[key][index] = paramValue;
				} else {

					// Otherwise add the value to the end of the array
					obj[key].push(paramValue);
				}
			} else if (!obj[paramName]) { // We're dealing with a string

				// If it doesn't exist, create property
				obj[paramName] = paramValue;
			} else if (obj[paramName] && typeof obj[paramName] === 'string') {

				// If property does exist and it's a string, convert it to an array
				obj[paramName] = [obj[paramName]];
				obj[paramName].push(paramValue);
			} else {

				// Otherwise add the property
				obj[paramName].push(paramValue);
			}
		}
	}
	return obj;
};

const hideDropdownElement = (element) => {
	$(element).parent().addClass('hide-dropdown');
	setTimeout(() => {
		$(element).parent().removeClass('hide-dropdown');
	}, 500);
};


/*
 * Validators
 */

const validatePass = (pass) => {
	if (pass === '' || pass.replace(/\s/g, '') === '') {
		return [false,
			'Please create a password.'];
	}
	if (pass.length < 8) {
		return [false,
			'Your password must be at least 8 characters.'];
	}
	if (!PASSWORD_REGEX.test(pass)) {
		return [false,
			'Your password must contain a number and an uppercase & lowercase letter.'];
	}
	return [true,
		null];
};


/**
 * Loading Indicators
 */

const isPositionToLoad = () => $(window).scrollTop() + $(window).height() > $(document).height() + SCROLL_LOAD_TOP;

const fireOnComponent = (component, callback) => {
	if (component.css('display') === 'block') {
		callback();
	}
};


/**
 * Exports
 */

module.exports = {
	showAlert,
	hideAlert,
	showModalWithId,
	showModalWithComponent,
	hideModalWithId,
	hideModal,
	getAllUrlParams,
	hideDropdownElement,
	validatePass,
	getCookie,
	isPositionToLoad,
	fireOnComponent
};
