/*
 * File: login-handler.js
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: April 10, 2024 at 12:10 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Modules
const validator = require('validator');

// Utilities
const { logoutUser } = require('../../modules/auth');
const { showAlert, hideAlert, getAllUrlParams } = require('../../modules/utilities');
const { loginUser } = require('../../modules/user');


/**
 * Handlers
 */

const handleLoginUser = async () => {

	// Get parameters
	const email = $('#email').val();
	const pass = $('#pass').val();

	// Validate parameters
	if (validator.isEmpty(email, { ignore_whitespace: true })) {
		showAlert('Please enter your email.');
		return;
	}
	if (validator.isEmpty(pass, { ignore_whitespace: true })) {
		showAlert('Please enter your password.');
		return;
	}

	// Get elements
	const button = $('#login-button');

	// Disable button
	button.attr('disabled', true);

	// Sign in user
	loginUser(email, pass).then(() => {

		// Redirect to dashboard
		window.location = '/dashboard';

	}).catch((error) => {

		// Clear password
		$('#pass').val('');

		// Enable button
		button.attr('disabled', false);

		// Show error
		let showedError = false;
		if (error != null && error.code === 101) {
			if (error.message !== 'Invalid username/password.' && error.message !== '') {
				showAlert(error.message);
				showedError = true;
			}
		}
		if (showedError !== true) {
			showAlert("Whoops. We can't find the email and password combination you entered. Please try again.");
		}
	});
};

const handleLoginOnLoad = async () => {

	// Check for login parameters on load
	const urlParams = getAllUrlParams();
	const email = urlParams.e;
	const pass = urlParams.p;
	const token = urlParams.t;

	// Validate parameters
	if (email != null && !validator.isEmpty(email, { ignore_whitespace: true })
        && pass != null && !validator.isEmpty(pass, { ignore_whitespace: true })
        && token === 'direct') {

		// Set content
		$('#email').val(email);
		$('#pass').val(pass);

		// Clear url parameters
		window.history.replaceState({}, document.title, window.location.href.split('?')[0]);

		// Attempt login
		handleLoginUser();
	}
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Handle click on login button
	$(document).on('click', '#login-button', () => {

		// Handle login user
		handleLoginUser();
	});

	// Handle enter key for button press
	$(document).keypress((e) => {
		if (e.keyCode === 13) {

			// Handle login user
			handleLoginUser();
		}
	});
};


/**
 * Input Handlers
 */

const createInputHandlers = () => {

	// Handle focus/blur
	$(document).on('focus', '#email, #pass', () => {

		// Hide alert
		hideAlert();
	});

	// Handle focus/blur
	$(document).on('blur', '#email, #pass', () => {

		// Hide alert
		hideAlert();
	});

	// Handle change content
	$(document).on('keyup', '#email, #pass', (e) => {
		if (e.keyCode !== 13) {

			// Hide alert
			hideAlert();
		}
	});
};


/**
 * Init
 */

exports.initialize = async () => {

	// Create action handlers
	createActionHandlers();

	// Create input handlers
	createInputHandlers();

	// Handle logout user
	await logoutUser(false);

	// Handle login on load
	handleLoginOnLoad();
};
