/*
 * File: authenticate-modal-handler.js
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: April 10, 2024 at 12:08 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Modules
const validator = require('validator');

// Utilities
const {
	showAlert, hideAlert, showModalWithId, hideModalWithId
} = require('../../../modules/utilities');


/**
 * Handlers
 */

exports.beginAuthenticationFlow = () => {

	// Show modal
	showModalWithId('authenticate-modal');
};

const handleAuthenticateUser = () => {

	// Get token
	const token = $('#authenticate-modal-token').val();

	// Validate parameters
	if (validator.isEmpty(token, { ignore_whitespace: true })) {
		showAlert('Please enter your current authenticator code.', 'authenticate-modal-input-alert');
		return;
	}

	// Get elements
	const button = $('#authenticate-modal-authenticate-button');

	// Disable button
	button.attr('disabled', true);

	// Create parameters
	const params = { token };

	// Enroll in authentication
	Parse.Cloud.run('authenticateUserWithParameters', params).then(() => {

		// Enable button
		button.attr('disabled', false);

		// Hide modal
		hideModalWithId('authenticate-modal');

	}).catch(() => {

		// Enable button
		button.attr('disabled', false);

		// Show error
		showAlert("We can't find a matching authenticator code. Please enter the current code from your authenticator.", 'authenticate-modal-input-alert');
	});
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Handle click on authenticate button
	$(document).on('click', '#authenticate-modal-authenticate-button', () => {

		// Handle authenticate user
		handleAuthenticateUser();
	});
};


/**
 * Input Handlers
 */

const createInputHandlers = () => {

	// Handle focus/blur
	$(document).on('focus', '#authenticate-modal-token', () => {

		// Hide alert
		hideAlert('authenticate-modal-input-alert');
	});

	// Handle focus/blur
	$(document).on('blur', '#authenticate-modal-token', () => {

		// Hide alert
		hideAlert('authenticate-modal-input-alert');
	});

	// Handle change content
	$(document).on('keyup', '#authenticate-modal-token', (e) => {
		if (e.keyCode !== 13) {

			// Hide alert
			hideAlert('authenticate-modal-input-alert');
		}
	});
};


/**
 * Init
 */

exports.initialize = async () => {

	// Create action handlers
	createActionHandlers();

	// Create input handlers
	createInputHandlers();
};
