/*
 * File: auth.js
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: April 10, 2024 at 12:08 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Constants
const { SESSION_TOKEN_COOKIE, USER_IMPERSONATION_COOKIE } = require('../../../constants/general.constants');


/**
 * Handlers
 */

const handleSessionToken = (token) => {
	if (token) { // Add token

		// Set cookie
		const cookieDomain = (process.env.ENV === 'development') ? '' : 'domain=ourwave.org;';
		document.cookie = `${SESSION_TOKEN_COOKIE}=${token}; expires=Thu, 01 Jan 2100 00:00:00 UTC; path=/; ${cookieDomain}`;

	} else { // Remove token

		// Remove cookie
		const cookieDomain = (process.env.ENV === 'development') ? '' : 'domain=ourwave.org;';
		document.cookie = `${SESSION_TOKEN_COOKIE}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; ${cookieDomain}`;
	}
};

const logoutUser = async (shouldRedirect, shouldReload) => {

	// Remove cookies
	const cookieDomain = (process.env.ENV === 'development') ? '' : 'domain=ourwave.org;';
	document.cookie = `${USER_IMPERSONATION_COOKIE}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; ${cookieDomain}`;

	// Remove headers
	Parse.CoreManager.set('REQUEST_HEADERS', null);

	// Handle session token
	handleSessionToken(null);

	// Logout user
	try { await Parse.Cloud.run('logoutUser', null); } catch (err) {}
	try { await Parse.User.logOut(); } catch (err) {}

	// Redirect to login
	if (shouldRedirect === true) {
		window.location = '/login';
	}
	if (shouldReload === true) {
		window.location.reload();
	}
};


/**
 * Exports
 */

module.exports = {
	handleSessionToken,
	logoutUser
};
