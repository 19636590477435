/*
 * File: user.js
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: April 10, 2024 at 11:50 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Modules
const moment = require('moment-timezone');

// Utilities
const { callAPIFunction } = require('./requests');
const { logoutUser, handleSessionToken } = require('./auth');

// Handlers
const { beginAuthenticationFlow } = require('../handlers/components/modals/authenticate-modal-handler');


/**
 * Handlers
 */

const handleAuthActions = (user) => {

	// Get session token
	const token = user.getSessionToken();

	// Handle session token
	handleSessionToken(token);
};

const loginUser = async (email, password) => {

	// Sign in user
	const { sessionToken } = await callAPIFunction('loginUser', { username: email, password });

	// Become user from session token
	await Parse.User.become(sessionToken);

	// Handle auth actions
	const user = Parse.User.current();

	// Handle auth actions
	handleAuthActions(user);
};

const handleUpdateUser = async () => {

	// Ensure current user exists
	const user = Parse.User.current();
	if (user == null) { // If no user, remove session token
		handleSessionToken(null);
		return;
	}

	// Get session token
	const token = user.getSessionToken();

	// Handle session token
	handleSessionToken(token);

	// Initialize should save
	let shouldUpdate = false;

	// If current timezone different than saved, update
	const timezone = moment.tz.guess();
	if (user.get('timezone') !== timezone) {
		user.set('timezone', timezone);
		shouldUpdate = true;
	}

	// Update user
	if (shouldUpdate === true) {
		try { await user.save(); } catch (error) { // Handle session errors
			if (error.code === 209) {

				// Logout user
				logoutUser(true);

			} else if (error.message && error.message.code === 506) {

				// Begin authentication flow
				beginAuthenticationFlow();
			}
		}
	}
};


/**
 * Initialize
 */

const initialize = () => {};


/**
 * Exports
 */

module.exports = {
	initialize,
	loginUser,
	handleUpdateUser,
	handleAuthActions
};
