/*
 * File: general.constants.js
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 30, 2024 at 3:18 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Roles
 */

exports.ROLES = {
	STANDARD: 'standard',
	ANALYTICS_ONLY: 'analytics_only',
	LIMITED: 'limited',
	REVIEWER: 'reviewer',
	SPECIALIST: 'specialist',
	ADMIN: 'admin',
	SUPER: 'super'
};


/**
 * Sources
 */

exports.SOURCES = {
	STORIES: 'stories',
	STORY: 'story',
	QUESTIONS: 'questions',
	QUESTION: 'question',
	RESOURCES: 'resources',
	SHARE: 'share',
	RECORD: 'record',
	UPDATE: 'update',
	ARTIST: 'artist',
	EXHIBIT: 'exhibit',
	EXHIBITS: 'exhibits',
	GUESTBOOK: 'guestbook',
	ALL: 'all'
};


/**
 * Review Statuses
 */

exports.REVIEW_STATUS = {
	WAITING_FOR_REVIEW: 'WAITING_FOR_REVIEW',
	IN_REVIEW: 'IN_REVIEW',
	REQUIRES_APPROVAL: 'REQUIRES_APPROVAL',
	REVIEW_COMPLETED: 'REVIEW_COMPLETED'
};


/**
 * Review Tags
 */

exports.REVIEW_TAGS = {
	SUICIDALITY: {
		id: 'SUICIDALITY',
		name: 'Suicidality Potential'
	},
	HOMICIDAILTY: {
		id: 'HOMICIDAILTY',
		name: 'Homicidality Potential'
	},
	TRIGGERING: {
		id: 'TRIGGERING',
		name: 'Trigger Potential'
	},
	MINOR: {
		id: 'MINOR',
		name: 'Subject Is Potentially A Minor'
	},
	PAST_MINOR: {
		id: 'PAST_MINOR',
		name: 'Subject Was Potentially A Minor'
	},
	STORY_NOT_APPROPRIATE: {
		id: 'STORY_NOT_APPROPRIATE',
		name: 'Not Appropriate'
	},
	STORY_NOT_APPLICABLE: {
		id: 'STORY_NOT_APPLICABLE',
		name: 'Not Applicable'
	}
};


/**
 * Reactions
 */

exports.REACTIONS = {
	reaction_a: {
		code: 'reaction_a',
		name: 'Thank you for sharing'
	},
	reaction_b: {
		code: 'reaction_b',
		name: 'Your story made me feel hopeful'
	},
	reaction_c: {
		code: 'reaction_c',
		name: 'Your story inspired me'
	},
	reaction_d: {
		code: 'reaction_d',
		name: 'I can relate to this'
	},
	reaction_e: {
		code: 'reaction_e',
		name: 'You are not alone'
	}
};


/**
 * Triggers
 */

exports.CONTENT_TRIGGERS = {
	SELF_HARM: 'SELF_HARM',
	DEROGATORY_CONTENT: 'DEROGATORY_CONTENT',
};


/**
 * General
 */

exports.OBFUSCATE_OPEN_CODE = '{~';
exports.OBFUSCATE_CLOSE_CODE = '~}';
exports.OBFUSCATE_REGEX = /\{~.*?~\}/g;
exports.SCROLL_LOAD_TOP = -50;
exports.OBFUSCATE_TEXT_REPLACE = '****';
exports.SESSION_TOKEN_COOKIE = 'e_s_token';
exports.DEFAULT_TIMEZONE = 'America/New_York';
exports.DEFAULT_LOCALE = 'en-US';
exports.PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;


/**
 * Headers
 */

exports.USER_IMPERSONATION_HEADER = 'x-user-imp-token';


/**
 * Cookies
 */

exports.USER_IMPERSONATION_COOKIE = 'user_imp_token';


/**
 * File Upload Limits (Bytes)
 */

exports.FILE_UPLOAD_LIMITS = {
	SURVIVOR_MEDIA: 10000000, // 10 MB
	GUESTBOOK_MEDIA: 10000000, // 10 MB
	RESOURCE_MEDIA: 10000000, // 10 MB
};


/**
 * Image Upload Resolution Limits (Pixels)
 */

exports.FILE_UPLOAD_RES_LIMITS = {
	SURVIVOR_MEDIA: {
		MIN: 100, // 100 pixels
		MAX: 3000 // 3000 pixels
	},
	GUESTBOOK_MEDIA: {
		MIN: 50, // 50 pixels
		MAX: 2000 // 2000 pixels
	},
	RESOURCE_MEDIA: {
		MIN: 300, // 300 pixels
		MAX: 2000 // 2000 pixels
	}
};


/**
 * Acceptable Image Ratios
 */

exports.ACCEPTABLE_IMAGE_RATIOS = {
	SURVIVOR_MEDIA: 2.8, // 1:2.8 (Both width and height)
	GUESTBOOK_MEDIA: 2.8, // 1:2.8 (Both width and height)
	RESOURCE_MEDIA: 2.8 // 1:2.8 (Both width and height)
};


/**
 * Acceptable File Types
 */

exports.ACCEPTABLE_FILE_TYPES = {
	SURVIVOR_MEDIA: {
		EXTENSIONS: [
			'png',
			'jpeg',
			'jpg'
		],
		MIME_TYPES: [
			'image/png',
			'image/jpeg',
			'image/jpg'
		]
	},
	GUESTBOOK_MEDIA: {
		EXTENSIONS: [
			'png'
		],
		MIME_TYPES: [
			'image/png'
		]
	},
	RESOURCE_MEDIA: {
		EXTENSIONS: [
			'png',
			'jpeg',
			'jpg'
		],
		MIME_TYPES: [
			'image/png',
			'image/jpeg',
			'image/jpg'
		]
	},
};


/**
 * Tag Families
 */

exports.TAG_FAMILIES = {
	location: {
		id: 'location',
		display: 'Location',
		title: 'I was at...'
	},
	perpetrator: {
		id: 'perpetrator',
		display: 'Perpetrator',
		title: 'The offender was a...'
	},
	race: {
		id: 'race',
		display: 'Race',
		title: 'I identify as...'
	},
	orientation: {
		id: 'orientation',
		display: 'Orientation',
		title: 'My sexual orientation is...'
	},
	gender: {
		id: 'gender',
		display: 'Gender',
		title: 'I identify as...'
	},
	identification: {
		id: 'identification',
		combineWith: 'gender',
		display: 'Identification',
		title: 'I identify as...'
	},
	age: {
		id: 'age',
		display: 'Age',
		title: 'I was...'
	},
	experiences: {
		id: 'experiences',
		display: 'Experiences',
		title: 'When this occurred I also experienced...'
	},
	industry: {
		id: 'industry',
		display: 'Industry',
		title: 'The industry I was working in when this occurred was...'
	},
	influence: {
		id: 'influence',
		display: 'Influence',
		title: 'I was under the influence of...'
	},
	region: {
		id: 'region',
		display: 'Region',
		title: 'I am in...'
	}
};
