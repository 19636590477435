/*
 * File: navigation.js
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: April 10, 2024 at 12:08 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Utilities
const { logoutUser } = require('./auth');
const { showModalWithId, hideModalWithId } = require('./utilities');


/**
 * Handlers
 */

function handleScrollHeader() {
	const scrollPos = $(document).scrollTop();
	$('.scrolling-header-container').each(function () {
		if (scrollPos > 70) {
			if (!($(this).hasClass('scrolling-header'))) {
				$(this).addClass('scrolling-header');
			}
		} else if ($(this).hasClass('scrolling-header')) {
			$(this).removeClass('scrolling-header');
		}
	});
}


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Enable Navigation Bar
	$(document).on('click', 'a', function () {
		if ($(this).attr('href') === '#' && $(this).attr('id') === 'toggle-app-menu') {
			$('#dropdown-menu').toggleClass('show');
			$('#menu-arrow-open').toggleClass('hide');
			$('#menu-arrow-close').toggleClass('hide');
			return false;
		}
		return undefined;
	});

	// Check Navigation Bar Display
	if ($('#dropdown-menu').length) {
		$(window).resize(() => {
			if ($(window).width() >= 1135) {
				if ($('#dropdown-menu').hasClass('show')) {
					$('#dropdown-menu').toggleClass('show');
					$('#menu-arrow-open').toggleClass('hide');
					$('#menu-arrow-close').toggleClass('hide');
				}
			}
		});
	}

	// Handle scroll header
	if ($('.scrolling-header-container').length) {
		$(document).ready(() => {
			handleScrollHeader();
		});
		$(document).scroll(() => {
			handleScrollHeader();
		});
	}

	// Handle click logout component
	if ($('#logout-link').length) {
		$(document).on('click', '#logout-link', () => {

			// Handle user logout
			logoutUser(true);
		});
	}

	// Handle cookie bar customize settings action
	$(document).on('click', '#end-impersonation-button', () => {

		// Handle user logout
		logoutUser(true);
	});

	// Handle review modal view action
	$(document).on('click', '[data-action="show-review-guide"]', () => {
		showModalWithId('review-guide-modal');
	});

	// Handle review modal hide action
	$(document).on('click', '[name="review-guide-close"]', () => {
		hideModalWithId('review-guide-modal');
	});

	// Handle toggle review guide mobile menu
	$(document).on('click', '[name="toggle-guide-menu"]', function () {
		$(this).next('[name="guide-dropdown-menu"]').toggleClass('show');
		$(this).find('[name="legal-menu-arrow-open"]').toggleClass('hide');
		$(this).find('[name="legal-menu-arrow-close"]').toggleClass('hide');
	});

	// Handle click on review guide navigation item
	$(document).on('click', '[name="guide-navigation-link"]', function () {

		// Get parameters
		const page = $(this).data('page');

		// Get parent elements
		const guideContainer = $(this).closest('[name="review-guide-container"]');

		// Select navigation links
		guideContainer.find('[name="guide-navigation-link"]').each(function () {
			$(this).removeClass('link-active');
			if ($(this).data('page') === page) $(this).addClass('link-active');
		});

		// Create mobile navigation title
		let guideMobileTitle = '';
		switch (page) {
			case 'review-guide-page-overview':
				guideMobileTitle = 'Overview';
				break;
			case 'review-guide-page-preparation':
				guideMobileTitle = 'Preparation and Self-Care';
				break;
			case 'review-guide-page-redacting':
				guideMobileTitle = 'Redacting Identifiable Content';
				break;
			case 'review-guide-page-trigger-warning':
				guideMobileTitle = 'Trigger Warning Stories';
				break;
			case 'review-guide-page-dont-post':
				guideMobileTitle = 'Decision to Not Post Stories';
				break;
			case 'review-guide-page-intensity':
				guideMobileTitle = 'Ranking the Intensity of Stories';
				break;
			case 'review-guide-page-suicidality':
				guideMobileTitle = 'Recognizing and Acting on Suicidality';
				break;
			case 'review-guide-page-homicidality':
				guideMobileTitle = 'Recognizing and Acting on Homicidality';
				break;
			case 'review-guide-page-minors':
				guideMobileTitle = 'Reporting Sexual Abuse of Minors';
				break;
			case 'review-guide-page-taking-care':
				guideMobileTitle = 'Taking Care of Yourself';
				break;
			default:
				break;
		}

		// Update mobile navigation title
		guideContainer.find('[name="guide-menu-title"]').html(guideMobileTitle);

		// Hide mobile navigation
		guideContainer.find('[name="guide-dropdown-menu"]').removeClass('show');
		guideContainer.find('[name="legal-menu-arrow-open"]').removeClass('hide');
		guideContainer.find('[name="legal-menu-arrow-close"]').removeClass('hide').addClass('hide');

		// Display component content
		guideContainer.find('[name="review-guide-page"]').each(function () {
			$(this).removeClass('hide-element').addClass('hide-element');
			if ($(this).attr('id') === page) $(this).removeClass('hide-element');
		});
	});
};


/**
 * Input Handlers
 */

const createInputHandlers = () => {};


/**
 * Init
 */

const initialize = () => {

	// Create input handlers
	createInputHandlers();

	// Create action handlers
	createActionHandlers();
};


/**
 * Exports
 */

module.exports = {
	initialize
};
