/*
 * File: requests.js
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: April 10, 2024 at 12:08 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Utilities
const { logoutUser } = require('./auth');

// Handlers
const { beginAuthenticationFlow } = require('../handlers/components/modals/authenticate-modal-handler');


/**
 * Handlers
 */

const callAPIFunction = async (name, params) => {

	// Run function
	try {
		const result = await Parse.Cloud.run(name, params);
		return result;
	} catch (error) {
		if (error.code === 209) {

			// Handle user logout
			logoutUser(false, true);

		} else if (error.message && error.message.code === 506) {

			// Begin authentication flow
			beginAuthenticationFlow();

		} else if (error.message && error.message === 'User does not have an active authentication session') {

			// Begin authentication flow
			beginAuthenticationFlow();
		}
		throw error;
	}
};


/**
 * Exports
 */

module.exports = {
	callAPIFunction
};
